import React from 'react'

import { Link } from 'gatsby'

import './ConfidentSpeakers.scss'

const ConfidentSpeakers = () => (
  <div className="confident-speakers">
    <div className="wrapper">
      <h2>Start your 30-day free trial today</h2>
      <br />
      <Link className="signup-button" to="/trial/organization">
        Request a Demo
      </Link>
    </div>
  </div>
)

export default ConfidentSpeakers
