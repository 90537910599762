import React from 'react'

import { Link } from 'gatsby'

import './StudentsAndEducators.scss'

const StudentsAndEducators = () => (
  <div className="students-educators">
    <div className="wrapper">
      <h2>
        <strong>
          Designed for Students <u>AND</u> Educators
        </strong>
      </h2>
      <br />
      <h4>
        Ovation's Organization subscription provides powerful tools designed to seamlessly deploy to students, monitor
        their usage, and guide their progress.
      </h4>
      <br />
      <h4>
        Start a 30-day free trial today and experience how Ovation can transform your students into confident and
        articulate communicators.
      </h4>
      <br />
      <Link className="signup-button" to="/trial/organization">
        Request a Demo
      </Link>
    </div>
  </div>
)

export default StudentsAndEducators
